import * as React from "react";
import { Link, PageProps } from "gatsby";
import Layout from "../components/layout/layout";
import { Breadcrumb } from "../components/breadcrumb/breadcrumb";
import Container from "../components/container/container";
import * as styles from "./cerez-politikasi.module.scss";

const GrdpPolicyPage: React.FC<PageProps> = ({ location }: PageProps) => {
  return (
    <Layout
      hideTips={true}
      seoConfig={{
        title:
          "Gizlilik Politikası ve Kişisel Verilerin Korunması Kanunu Aydınlatma Metni",
        description:
          "Bisolnatur® websitesi gizlilik politikası ve aydınlatma metnini inceleyebilirsiniz.",
      }}
    >
      <Breadcrumb
        pathname={location.pathname}
        noBg={true}
        className={styles.breadcrumb}
      />
      <Container fullWidth>
        <Container>
          <section className={styles.holder}>
            <h1>
              Gizlilik Politikası ve Kişisel Verilerin Korunması Kanunu
              <br />
              Aydınlatma Metni
            </h1>
            <h2 className="orange underline">
              1. Kişisel Verilerin Korunması Kanunu Aydınlatma Metni
            </h2>
            <p>
              <b>Veri Sorumlusu:</b> Opella Healthcare Tüketici Sağlığı A.Ş.
              <br />
              <br />
              <b>Adres:</b> Esentepe Mah. Büyükdere Cad. 193 Apt.No:193/11 34394
              Şişli/Istanbul
              <br />
              <br />7 Nisan 2016 tarihli ve 29677 Sayılı Resmî Gazete’de
              yayımlanan{" "}
              <b>
                6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”)
              </b>{" "}
              uyarınca, Opella tarafından, Veri Sorumlusu sıfatıyla, kişisel
              verileriniz, iş amaçlarıyla bağlı olarak, aşağıda açıklandığı
              çerçevede kullanılmak, kaydedilmek, saklanmak, güncellenmek,
              aktarılmak ve/veya sınıflandırılmak suretiyle işlenecektir.
              <br />
              <br />
              Bu kapsamda Opella tarafından başta özel hayatın gizliliği olmak
              üzere, kişilerin temel hak ve özgürlüklerini korumak ve kişisel
              verilerin korunması amacıyla düzenlenen Kanun ve Yönetmelikler
              gereğince Opella , kişisel verilerinizin hukuka aykırı olarak
              işlenmesini önleme, hukuka aykırı olarak erişilmesini önleme ve
              muhafazasını sağlama amacıyla, uygun güvenlik düzeyini temin
              etmeye yönelik tüm teknik ve idari tedbirleri almaktadır.
              <br />
              <br />
              Bu metnin hedef kitlesi, www.bisolnatur.com.tr sitesini{" "}
              <b>(“Site”)</b> ziyaret eden ve Opella tarafından kişisel verileri
              işlenen tüm gerçek kişilerdir.
              <br />
              <br />
              Site’de yer alan çerezlerin kullanımına ilişkin detaylı bilgilere
              bu{" "}
              <Link to="/cerez-politikasi/">
                <b>link</b>
              </Link>{" "}
              üzerinde yer alan “Çerez Politikası’ndan" erişebilirsiniz.
              <br />
              <br />
              Veri sorumlusu sıfatıyla işlenen kişisel verilere, burada
              belirtilenlerle sınırlı sayıda olmamak üzere aşağıda yer
              verilmektedir;
              <br />
              <br />
              <h3 className="blue">Toplanan Kişisel Verileriniz </h3>
              Site’yi kullanmanıza ilişkin olarak:
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Konumumu bul linkine
              tıkladığınızda, konum bilginiz,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• “Bize Ulaşın” kısmındaki formu
              doldurmanız halinde adınız soyadınız, telefon numaranız
              (opsiyonel), e-mail adresiniz, sağlık mesleği mensubu olup
              olmadığınıza ilişkin bilgi ve yazacağınız mesaj içeriği bilginiz,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Anketler ve yarışmalar kapsamında
              iletilen, ad-soyadı, doğum tarihi, T.C. Kimlik numarası, telefon
              numarası, e-mail adresi, adres, fotoğraf, video kayıtları ve
              yukarıda belirtilen kanallar üzerinden herhangi bir şekilde
              tarafımızla paylaştığınız diğer her türlü kişisel verileriniz,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Opella’nın Site’nin yer
              sağlayıcısı olması nedeniyle Site’yi kullanımınıza ilişkin erişim
              ve log kayıtlarınız,
              <br />
              toplanacaktır. Çerez kullanımına ilişkin detaylı bilgi için Çerez
              Politikası’na göz atabilirsiniz.
              <br />
              Aksi açıkça belirtilmedikçe, işbu metin kapsamında
              kullanılan“kişisel veri” ifadesi yukarıda sayılan bilgileri
              kapsayacaktır.
              <br />
              <br />
              <h3 className="blue">Kişisel Verilerinizin İşlenme Amaçları</h3>
              Opella, KVKK’nın 5. ve 6. maddelerinde belirtilen kişisel veri
              işleme şartları dâhilinde, tarafınızca paylaşılan kişisel
              verilerinizi;
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Size en yakın eczanenin bulunması,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Bize Ulaşın kısmından bize mesaj
              göndermeniz halinde kişisel verileriniz gönderdiğiniz mesaj
              içeriğindeki soru, şikayet ve taleplerinizin cevaplandırılarak bu
              cevabın size ulaştırılması için sizinle iletişim kurulması
              amacıyla,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Bize Ulaşın kısmında
              göndereceğiniz mesajla birlikte bilgilerinizin size yönelik reklam
              ve ticari iletişim faaliyetlerinde kullanılmasına onay vermeniz
              halinde ise kişisel verileriniz Opella tarafından sunulan ürün ve
              hizmetlerden sizleri faydalandırmak için, Opella’nın ticari ve iş
              stratejilerinin belirlenmesi ve uygulanması, pazarlama
              faaliyetlerinin yapılması, hediye ve promosyon ve sair
              dergi/süreli yayın gönderimi, iş geliştirme ve planlama
              faaliyetlerinin gerçekleştirilmesi dahil ve fakat bunlarla sınırlı
              olmamak üzere gerekli çalışmaların yürütülmesi,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Şirketimiz tarafından sunulan ürün
              ve hizmetlerden sizleri ve/veya temsil ettiğiniz kurum ve
              kuruluşları faydalandırmak için, Şirketimizin ticari ve iş
              stratejilerinin belirlenmesi ve uygulanması, pazarlama
              faaliyetlerinin yapılması, iş geliştirme ve planlama
              faaliyetlerinin gerçekleştirilmesi dahil ve fakat bunlarla sınırlı
              olmamak üzere gerekli çalışmaların yürütülmesi,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Veri tabanı oluşturarak,
              listeleme, raporlama, doğrulama, analiz ve değerlendirmeler
              yapmak, istatistiki bilgiler üretilmesi ve gerektiğinde bunları
              işin uzmanları ile paylaşılması,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Internet reklamcılığı, Targeting,
              re-targeting (=hedefleme ve yeniden hedefleme), çapraz satış,
              kampanya, fırsat ve ürün/hizmet reklamları gibi size özel ürün ve
              hizmetlere yönelik fırsatların sunulması amacıyla işlenmesi, bu
              amaçla Çerez kullanılması, tercihlerinizi ve son satın
              almalarınızı göz önünde bulundurarak ticari tekliflerde
              bulunulması,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Şirketimiz ve ürünlerimiz hakkında
              bilgilendirme amacıyla doğrudan bizimle paylaşmış olduğunuz
              iletişim kanalları üzerinden sizinle irtibat kurulması,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Şirketimizin insan kaynakları
              politikalarının yürütülmesi,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• İletişim alanında şirketimiz ve
              ürünlerimizle ilgili haber, gelişme ve bilgilendirme amaçlı
              dijital ve basılı ortamlarda kullanılması,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• İş ortaklarımız, tedarikçilerimiz
              veya sair üçüncü kişilerle birlikte sunulan ürün ve hizmetlere
              ilişkin sözleşme gereklerinin ve finansal mutabakatın sağlanması,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• İş ortağı/müşteri/tedarikçi
              (yetkili veya çalışanları) ilişkilerinin kurulması,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Şirketimizin düzenlediği eğitim,
              seminer veya organizasyonlara katılım sağlanması,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Hediye ve promosyon ve sair
              dergi/süreli yayın gönderimi,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Şikayet, itiraz, talep, öneri,
              memnuniyet gibi bildirimlerin sizlere daha iyi hizmet verebilmek
              için bildirim yönetim sistemimizde kayıt altında tutulması;
              <br />
              Yukarıda ifade edilen amaçlar dışında kanun ve mevzuatlar
              tarafından öngörülen yükümlülüklerimizin yerine getirilmesi gibi
              amaçlar dahilinde işlenebilecektir.
              <br />
              <br />
              <h3 className="blue">
                Kişisel Verilerinizin Toplanma Yöntemi ve Hukuki Sebepleri
              </h3>
              Opella veya Opella’nın anlaşmalı olduğu üçüncü kişi iş ortakları
              tarafından kişisel verileriniz
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Site’yi ziyaret etmeniz halinde
              hukuki yükümlülüğümüzün yerine getirilmesi hukuki sebebine
              dayanarak Site’yi ziyaretinize ilişkin log kayıtlarının otomatik
              olarak kaydedilmesi yoluyla ve
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Site’deki Bize Ulaşın kısmından
              bize talep, şikayet ve önerilerinizi iletmeniz halinde meşru
              menfaat hukuki sebebine dayanarak otomatik yöntemlerle ve
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Bize Ulaşın kısmında bize yönelik
              talep, şikayet ve önerilerinizi iletirken kişisel verilerinizin
              size yönelik reklam faaliyetlerinde kullanılmak üzere işlenmesine
              açık rıza vermeniz halinde, bu açık rıza hukuki sebebine dayanarak
              otomatik yöntemlerle ve
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Kullanılan zorunlu çerezler ile
              meşru menfaat hukuki sebebine dayanarak; ayrıca opsiyonel
              çerezlerin kullanımına izin vermeniz halinde açık rıza hukuki
              sebebine dayanarak otomatik yöntemlerle,
              <br />
              toplanmaktadır.
              <br />
              <br />
              <h3 className="blue">
                İşlenen Kişisel Verilerin Kimlere ve Hangi Amaçla
                Aktarılabileceği
              </h3>
              Opella toplamış olduğu kişisel verileri; KVKK tarafından öngörülen
              temel ilkelere uygun olarak ve KVKK’nın 8. ve 9. maddelerinde
              belirtilen kişisel veri işleme şartları ve amaçları dahilinde
              olmak kaydıyla yukarıda açıklanan amaçlarla;
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Düzenleyici ve denetleyici
              kurumlara, kişisel verilerinizi tabi olduğu kanunlarında açıkça
              talep etmeye yetkili olan kamu kurum veya kuruluşlara kuruluşlara
              hukuki yükümlülüklerimizin yerine getirilmesi amacıyla,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Site’nin kullanım, bakım ve
              yönetimine ilişkin destek hizmeti aldığımız üçüncü kişi ajans ve
              iş ortaklarımızla,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Hukuk, vergi vb. benzeri alanlarda
              destek alınan danışmanlık firmaları, yasal takip süreçleri ile
              ilgili zorunlu kişilere, kurum ve kuruluşlara ve denetimciler de
              dâhil olmak üzere danışmanlık aldığımız üçüncü kişilere iş
              ortakları, hizmet alınan üçüncü kişi, yetkilendirilen kişi ve
              kuruluşlara aktarılabilecektir.
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Site’nin Opella’nın yurtdışında
              bulunan bağlı şirketinin sunucularında barındırılıyor olması
              nedeniyle yurtdışında bulunan Opella bağlı şirketine,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Depolama, arşivleme, bilişim
              teknolojileri desteği (sunucu, hosting, program, bulut bilişim),
              satış, pazarlama gibi alanlarda destek aldığımız üçüncü kişilere,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Satış, pazarlama ve Opella’nın
              faaliyetleri ile ilgili sair alanlarda destek veren üçüncü
              kişilere (e-posta gönderimi, kampanya oluşturulması amacı ile
              reklam firmaları, CRM desteği veren firmalar ve benzeri) ile kurum
              ve kuruluşlara aktarılabilmektedir.
              <br />
              <br />
              <br />
              <h3 className="blue">İlgili Kişinin Hakları</h3>
              Opella, Anayasa’nın 20nci ve KVKK’nun 11inci maddelerine uygun
              olarak kişisel veri sahibinin bilgi talep etmesi durumunda gerekli
              bilgilendirmeyi yapmaktadır.
              <br />
              <br />
              Bu kapsamda işlenecek kişisel verilerinize ilişkin olarak
              Opella’ya <i>info@sanofi.com.tr</i> e-posta adresi aracılığıyla
              e-posta göndermek suretiyle ya da{" "}
              <i>
                “Esentepe Mah. Büyükdere Cad. 193 Apt.No:193/11 34394
                Şişli/İstanbul Türkiye”
              </i>{" "}
              adresi aracılığıyla yazılı olarak başvurarak:
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(i) Kişisel verilerinizin işlenip
              işlenmediğini öğrenme,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(ii) Kişisel verilerinizin
              işlenmişse buna ilişkin bilgi talep etme,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(iii) Kişisel verilerinizin işlenme
              amacını ve bu amaca uygun kullanılıp kullanılmadığını öğrenme,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(iv) Yurt içinde ve yurtdışında
              kişisel verilerinizin aktarıldığı üçüncü kişileri öğrenme,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(v) Kişisel verilerinizin eksik veya
              yanlış işlenmiş olması halinde bunların düzeltilmesini isteme,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(vi) Kişisel verilerinizin
              işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde
              silinmesini veya yok edilmesini isteme,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(vii) Kişisel verilerinizin talebim
              üzerine düzeltilmesi veya güncellenmesi ya da talebiniz üzerine
              silinmesi halinde bunun kişisel verilerinizin aktarıldığı üçüncü
              kişilere bildirilmesini isteme,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(viii) İşlenecek kişisel
              verilerinizin otomatik sistemler vasıtasıyla analiz edilmesi
              suretiyle şahsınız aleyhine bir sonuç ortaya çıkması halinde buna
              itiraz etme ve
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(ix) Kişisel verilerinizin kanuna
              aykırı olarak işlenmesi sebebiyle zarara uğramanız halinde
              zararınızın giderilmesini isteme hakkına
              <br />
              sahipsiniz.
              <br />
              <br />
              Opella, talebin niteliğine göre talebi en kısa sürede ve en geç 30
              (otuz) gün içerisinde ücretsiz olarak sonuçlandıracaktır.
              Taleplerin Opella tarafından sonuçlandırılmasına ilişkin ayrıca
              bir maliyet doğması halinde kişisel verileri Koruma Kurulunca
              belirlenen tarifedeki ücretler Sanofi tarafından talep edilebilir.
              Kişisel verilerinizin açık rıza ile işlendiği hallerde söz konusu
              açık rızanızı geri almanız durumunda söz konusu açık rızaya dayalı
              işlemenin gerekli olduğu programdan çıkarılacağınızı belirtmek
              isteriz.
              <br />
              <br />
              KVKK Başvuru Formu'na ulaşmak için{" "}
              <Link to="/KVKK-Basvuru-Formu.pdf">
                <b>tıklayınız.</b>
              </Link>
            </p>
            <h2 className="orange underline">
              2. Web Sitesi Gizlilik Politikamız
            </h2>
            <p className="text-align-left explanation pb15">
              Opella, müşteri memnuniyetine ve güvenliğine verdiği önem
              sebebiyle, web sitemizde vermiş olduğunuz bilgilerin gizliliğini
              korumak amacıyla aşağıda belirtilen temel kuralları
              benimsemektedir.
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Web sitemizden yalnızca
              ürün/hizmet başvurusu ve bilgi güncelleme amaçlı bilgi girişi
              yapılmaktadır.
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Web sitemize girilen bilgilerin
              güvenliği açısından Opella sistem ve internet altyapısı en
              güvenilir seviyede tutularak gerekli önlemler alınmıştır.
              Müşterilerimizin web sitemize ürün/hizmet başvurusu ve bilgi
              güncelleme amaçlı girmiş oldukları bilgiler diğer internet
              kullanıcıları tarafından görüntülenemez.
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Opella, söz konusu bilgileri
              müşterilerin onayı dışında ya da yasal bir yükümlülük altında
              bulunmadığı sürece veya faaliyet ve işlemlerini yerine
              getirebilmek amacıyla mevzuata uygun olarak bu verileri destek
              hizmet aldığı 3.kişilerle paylaşmadığı sürece herhangi bir üçüncü
              şahıs, kurum ve kuruluş ile paylaşmayacaktır. Opella destek
              hizmetler almak amacıyla farklı kuruluşlarla çalışılması
              durumunda, bu firmaların Opella gizlilik standartlarına ve
              şartlarına uymalarını sağlayacaktır.
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Opella, bu bilgileri sadece
              gerekli yetkiler ve yasal düzenlemeler çerçevesinde
              açıklayabilecektir.
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Site diğer web sitelerine link
              vermektedir. Gizlilik Politikamız'da yer alan taahhütlerimiz
              sadece web sitemiz içerisinde geçerlidir ve diğer web sitelerini
              kapsamamaktadır. Web sitemizden link ile gidilecek diğer web
              sitelerinde, o sitelere ait kullanım şartları ve gizlilik
              politikası geçerli olacaktır. Site’den reklam, banner, içerik
              maksatlı veya başka bir maksat ile geçilen diğer web sitelerinin
              bilgi kullanımı, etik ilkeleri, gizlilik prensipleri, nitelik ve
              servis kalitesi ile bu sitelerde oluşabilecek herhangi bir maddi /
              manevi zarar ve kayıplardan Opella sorumlu değildir.
              <br />
              Bu Site’yi ziyaret etmeniz ve Site vasıtasıyla sunduğumuz
              hizmetlerden yararlanmanız sırasında size ve talep ettiğiniz
              hizmetlere ilişkin olarak elde ettiğimiz bilgilerin ne şekilde
              kullanılacağı ve korunacağı işbu "Gizlilik Politikamız"da
              belirtilen şartlara tabidir. Site’yi ziyaret etmekle ve Site
              vasıtasıyla sunduğumuz hizmetlerden yararlanmayı talep etmekle
              işbu "Gizlilik Politikamız"da belirtilen şartları kabul
              etmektesiniz.
              <br />
              "Gizlilik Politikamız" ve bu hükümlerde Opella tarafından zaman
              zaman yapılan değişiklikler tarafları bağlar. Aksi yazılı olarak
              ayrıca kararlaştırılmadıkça Opella’ya karşı bu hükümlere aykırı
              hiç bir talepte bulunulamaz ve hak ileri sürülemez.
            </p>
          </section>
        </Container>
      </Container>
    </Layout>
  );
};

export default GrdpPolicyPage;
